<template>
  <!-- <InformRmm v-if="showInformRmmAlert" 
    @close="showInformRmmAlert = false"
  /> -->
  <InformRmmModal v-if="showInformRmmModal" 
    @close="showInformRmmModal = false"
    :visible="showInformRmmModal"
  />
  <!-- <LimitExceeded v-if="showLimitExceededAlert" 
    @close="showLimitExceededAlert = false"
  /> -->
  <LimitExceededModal v-if="showLimitExceededModal" 
    @close="showLimitExceededModal = false"
    :visible="showLimitExceededModal"
  />
  <div v-if="!showCheckoutPage" class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-9">
          <div class="card tim-card radius-20">
            <div v-if="isOrderCompleted" class="card-content">
              <div v-if="!isMonetaryProgram">
                <div class="jersey-selection d-flex justify-content-between mb-1 align-items-end">
                  <h4 class="order-subtitle mb-0 fw-600 uppercase" style="color: #09101D;">{{ customer.translations.shorts }}</h4>
                  <button class="guide-btn no-btn m-0" @click="openModal">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <g clip-path="url(#clip0_2931_3764)">
                        <path d="M10.7477 20.5106L20.4568 10.4243C21.8438 8.98339 21.8169 7.56944 20.376 6.18242L17.4942 3.40838C16.0533 2.02136 14.6393 2.0483 13.2523 3.4892L3.5432 13.5755C2.15619 15.0164 2.18313 16.4303 3.62402 17.8173L6.50581 20.5914C7.94671 21.9784 9.36067 21.9515 10.7477 20.5106Z" stroke="#C8102F" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M12.5588 4.20972L16.161 7.67726" stroke="#C8102F" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M4.23672 12.855L7.11852 15.629" stroke="#C8102F" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M7.04542 9.93712L10.613 13.4407" stroke="#C8102F" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M9.78479 7.09155L11.9461 9.17208" stroke="#C8102F" stroke-width="1.5" stroke-linecap="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_2931_3764">
                          <rect width="24" height="24" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    Show {{ customer.translations.show_size_guide }}
                  </button>
                  <SizeGuideModal
                    :visible="isModalVisible"
                    :currentUser="currentUser"
                    :customer="customer"
                    :vendor="vendor"
                    :type="'shorts'"
                    @close="isModalVisible = false"
                  />
                </div>
                <!-- shorts table -->
                  <table class="table-striped table light-bg m-0 order-table order-table-edit radius-20 colored-th-table ">
                  <thead>
                  <tr>
                    <th class="fs-14">{{ customer.translations.colour }}</th>
                    <th class="fs-14" v-for="shortsVariant in shortsVariants" :key="shortsVariant.id">{{ shortsVariant.size }}</th>
                    <th class="fs-14">{{ customer.translations.total }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="short in shorts" :key="short.id" :id="`short_${short.id}`">
                    <td>
                      <div class="d-flex flex-column gap-1 align-items-center">
                        <img :src="short.image_url" class="product-img" width="74" alt="Short Image" />
                        <p style="color: #4F2D2CE5; font-size: 11px;" class="mb-0">
                          {{ customer.soccer_sports ? short.color : short.title }}
                        </p>
                      </div>
                    </td>

                    <td v-for="shortsVariant in shortsVariants" :key="shortsVariant.id">
                      <div v-if="matchingShortVariant(short, shortsVariant.size)">
                        <div class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1">
                          <button v-if="hasInventory(shortsVariant)" @click="decrementShortQuantity(short.id, shortsVariant.id)" type="button" class="no-btn">-</button>
                          <input
                              type="number"
                              class="w-100 text-center"
                              :id="variantInputId(short, shortsVariant)"
                              @input="validateInput('shorts', short.id, shortsVariant.id, shortsVariant.quantity)"
                              v-model="shortsVariantQuantities[short.id].variants[shortsVariant.id]"
                              :value="shortsVariantQuantities[short.id].variants[shortsVariant.id] || 0"
                              @blur="checkValidations(short.id, shortsVariant.id, 'Shorts')"
                              min="0"
                              :disabled="!hasInventory(shortsVariant)"
                          />
                          <button v-if="hasInventory(shortsVariant)" @click="incrementShortQuantity(short.id, shortsVariant.id)" type="button" class="no-btn">+</button>
                        </div>
                        <p v-if="hasInventory(shortsVariant)" class="inventory-count m-0">{{ shortsVariant.quantity }} {{ customer.translations.available }}</p>
                        <p v-else class="inventory-count m-0">{{ customer.translations.out_of_stock }}</p>
                      </div>
                      <div v-else>
                        <div class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1 opacity-8">
                          -
                        </div>
                        <p class="inventory-count m-0">{{ customer.translations.sold_out }}</p>
                      </div>
                    </td>

                    <td>{{ getShortTotalQuantity(short.id) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- socks table -->
              <div v-if="!isMonetaryProgram">
                <div class="jersey-selection d-flex justify-content-between my-1 align-items-end">
                  <h4 class="order-subtitle mb-0 fw-600 uppercase" style="color: #09101D;">{{ customer.translations.socks }}</h4>
                </div>
                <table class="table-striped table light-bg m-0 order-table order-table-edit radius-20 colored-th-table">
                <thead>
                  <tr>
                    <th class="fs-14">{{ customer.translations.colour }}</th>
                    <th class="fs-14" v-for="socksVariant in socksVariants" :key="socksVariant.id">{{ socksVariant.size }}</th>
                    <th class="fs-14">{{ customer.translations.total }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sock in socks" :key="sock.id" :id="`sock_${sock.id}`">
                    <td>
                      <div class="d-flex flex-column gap-1 align-items-center">
                        <img :src="sock.image_url" class="product-img" width="74" alt="sock Image" />
                        <p style="color: #4F2D2CE5; font-size: 11px;">
                          {{ customer.soccer_sports ? sock.color : sock.title }}
                        </p>
                      </div>
                    </td>
                    
                    <td v-for="socksVariant in socksVariants" :key="socksVariant.id">
                      <div v-if="matchingSocksVariant(sock, socksVariant.size)">
                        <div class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1">
                          <button v-if="hasInventory(socksVariant)" @click="decrementSockQuantity(sock.id, socksVariant.id)" type="button" class="no-btn">-</button>
                          <input
                            type="number"
                            class="w-100 text-center"
                            min="0"
                            @input="validateInput('socks', sock.id, socksVariant.id, socksVariant.quantity)"
                            @blur="checkValidations(sock.id, socksVariant.id, 'Socks')"
                            :disabled="!hasInventory(socksVariant)"
                            v-model="socksVariantQuantities[sock.id].variants[socksVariant.id]"
                          />
                          <button v-if="hasInventory(socksVariant)" @click="incrementSockQuantity(sock.id, socksVariant.id)" type="button" class="no-btn">+</button>
                        </div>
                        <p v-if="hasInventory(socksVariant)" class="inventory-count m-0">{{ socksVariant.quantity }} available</p>
                        <p v-else class="inventory-count m-0">{{ customer.translations.out_of_stock }}</p>
                      </div>
                      <div v-else>
                        <div class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1 opacity-8">
                          -
                        </div>
                        <p class="inventory-count m-0">{{ customer.translations.sold_out }}</p>
                      </div>
                    </td>
                    <td>{{ getSockTotalQuantity(sock.id) }}</td>
                  </tr>
                </tbody>
              </table>
              </div>
              <!-- balls table -->
              <div class="jersey-selection d-flex justify-content-between my-1 align-items-end">
                <h4 class="order-subtitle mb-0 fw-600 uppercase" style="color: #09101D;">{{ customer.translations.soccer_balls }}</h4>
              </div>
              <table class="table-striped table light-bg m-0 order-table order-table-edit radius-20 colored-th-table">
                <thead>
                  <tr>
                    <th class="fs-14">{{ customer.translations.colour }}</th>
                    <th class="fs-14" v-for="ballsVariant in ballsVariants" :key="ballsVariant.id">{{ ballsVariant.size }}</th>
                    <th class="fs-14">{{ customer.translations.total }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="ball in balls" :key="ball.id" :id="`ball_${ball.id}`">
                    <td>
                      <div class="d-flex flex-column gap-1 align-items-center">
                        <img :src="ball.image_url" class="product-img" width="74" alt="ball Image" />
                        <p style="color: #4F2D2CE5; font-size: 11px;">
                          {{ customer.soccer_sports ? ball.color : ball.title }}
                        </p>
                      </div>
                    </td>
                    
                    <td v-for="ballsVariant in ballsVariants" :key="ballsVariant.id">
                      <div v-if="matchingBallsVariant(ball, ballsVariant.size)">
                        <div class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1">
                          <button v-if="hasInventory(ballsVariant)" @click="decrementBallQuantity(ball.id, ballsVariant.id)" type="button" class="no-btn">-</button>
                          <input
                            type="number"
                            class="w-100 text-center"
                            min="0"
                            @input="validateInput('balls', ball.id, ballsVariant.id, ballsVariant.quantity)"
                            @blur="checkValidations(ball.id, ballsVariant.id, 'Balls')"
                            :disabled="!hasInventory(ballsVariant)"
                            v-model="ballsVariantQuantities[ball.id].variants[ballsVariant.id]"
                          />
                          <button v-if="hasInventory(ballsVariant)" @click="incrementBallQuantity(ball.id, ballsVariant.id)" type="button" class="no-btn">+</button>
                        </div>
                        <p v-if="hasInventory(ballsVariant)" class="inventory-count m-0">{{ ballsVariant.quantity }} available</p>
                        <p v-else class="inventory-count m-0">{{ customer.translations.out_of_stock }}</p>
                      </div>
                      <div v-else>
                        <div class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1 opacity-8">
                          -
                        </div>
                        <p class="inventory-count m-0">{{ customer.translations.sold_out }}</p>
                      </div>
                    </td>
                    <td>{{ getBallTotalQuantity(ball.id) }}</td>
                  </tr>
                </tbody>
              </table>
              <!-- medals table -->
              <div class="jersey-selection d-flex justify-content-between my-1 align-items-end">
                <h4 class="order-subtitle mb-0 fw-600" style="color: rgb(9, 16, 29);">{{ customer.translations.medals }}</h4>
              </div>
              <table class="table-striped table light-bg m-0 order-table order-table-edit radius-20 colored-th-table">
                <thead>
                  <!--<tr v-for="medalsVariant in medalsVariants">-->
                  <tr>
                    <th class="text-center fs-14">{{ customer.translations.colour }}</th>
                    <!-- <th class="fs-14" :key="medalsVariant.id">{{ medalsVariant.size }}</th> -->
                    <th class="text-center fs-14">{{ customer.translations.medal }}</th>
                    <th class="text-center fs-14">{{ customer.translations.total }}</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="medalVariant in medalsVariants">
                    <tr v-for="medal in medals">
                      <template v-if="medalVariant.size == 'ENGLISH' && rmm.show_english_medals == true">
                        <td class="text-center">
                          <img 
                            :src="medalsVariants.indexOf(medalVariant) === 0 
                              ? require('../../assets/images/soccer_medal_english.png') 
                              : require('../../assets/images/soccer_medal_french.png')"
                            class="product-img" 
                            width="74" 
                            alt="Medal Image" 
                          />
                        </td>
                        <!-- <td v-for="medalVariant in medalsVariants" class="text-center"> -->
                        <td class="text-center">
                          <div v-if="matchingMedalsVariant(medal, medalVariant.size)">
                            <p>{{ medalVariant.size }}</p>
                            <div class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1">
                              <button v-if="hasInventory(medalVariant)" @click="decrementMedalQuantity(medal.id, medalVariant.id)" type="button" class="no-btn">-</button>
                              <input
                                type="number"
                                class="w-100 text-center"
                                min="0"
                                @input="validateInput('medals', medal.id, medalVariant.id, medalVariant.quantity)"
                                @blur="checkValidations(medal.id, medalVariant.id, 'Medals')"
                                :disabled="!hasInventory(medalVariant)"
                                :id="variantInputId(medal, medalVariant)"
                                v-model="medalsVariantQuantities[medal.id].variants[medalVariant.id]"
                              />
                              <button v-if="hasInventory(medalVariant)" @click="incrementMedalQuantity(medal.id, medalVariant.id)" type="button" class="no-btn">+</button>
                            </div>
                            <p class="inventory-count m-0">{{ medalVariant.quantity }} available</p>
                          </div>
                          <div v-else>
                            <div class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1 opacity-8">
                              -
                            </div>
                            <p class="inventory-count m-0">{{ customer.translations.sold_out }}</p>
                          </div>
                        </td>
                        <td class="text-center">
                        {{ getMedalTotalQuantity(medal.id, medalVariant.id) }}
                        </td>
                      </template>
                      <template v-if="medalVariant.size == 'FRENCH' && rmm.show_french_medals == true">
                        <td class="text-center">
                          <img 
                            :src="medalsVariants.indexOf(medalVariant) === 0 
                              ? require('../../assets/images/soccer_medal_english.png') 
                              : require('../../assets/images/soccer_medal_french.png')"
                            class="product-img" 
                            width="74" 
                            alt="Medal Image" 
                          />
                        </td>
                        <!-- <td v-for="medalVariant in medalsVariants" class="text-center"> -->
                        <td class="text-center">
                          <div v-if="matchingMedalsVariant(medal, medalVariant.size)">
                            <p>{{ medalVariant.size }}</p>
                            <div class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1">
                              <button v-if="hasInventory(medalVariant)" @click="decrementMedalQuantity(medal.id, medalVariant.id)" type="button" class="no-btn">-</button>
                              <input
                                type="number"
                                class="w-100 text-center"
                                min="0"
                                @input="validateInput('medals', medal.id, medalVariant.id, medalVariant.quantity)"
                                @blur="checkValidations(medal.id, medalVariant.id, 'Medals')"
                                :disabled="!hasInventory(medalVariant)"
                                :id="variantInputId(medal, medalVariant)"
                                v-model="medalsVariantQuantities[medal.id].variants[medalVariant.id]"
                              />
                              <button v-if="hasInventory(medalVariant)" @click="incrementMedalQuantity(medal.id, medalVariant.id)" type="button" class="no-btn">+</button>
                            </div>
                            <p class="inventory-count m-0">{{ medalVariant.quantity }} available</p>
                          </div>
                          <div v-else>
                            <div class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1 opacity-8">
                              -
                            </div>
                            <p class="inventory-count m-0">{{ customer.translations.sold_out }}</p>
                          </div>
                        </td>
                        <td class="text-center">
                        {{ getMedalTotalQuantity(medal.id, medalVariant.id) }}
                        </td>
                      </template>
                    </tr>
                  </template>
                  <tr>
                    <td colspan="3">
                      <div class="d-flex justify-content-center w-75 mx-auto gap-2">
                        <div class="d-flex flex-column gap-2 justify-content-center align-items-center">
                          <img
                            :src="require('../../assets/images/optional_medal.png')"
                            class="product-img" 
                            width="74" 
                            alt="Medal Image" 
                          />
                          <p class="d-flex gap-1" style="color: #996A13; font-family: 'Inter'; font-size: 12px;">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8451 13.5L15.8551 13.49L8.85947 1.49L8.84948 1.5C8.67958 1.2 8.36978 1 8.00001 1C7.63024 1 7.33043 1.2 7.15054 1.5L7.14055 1.49L0.144917 13.49L0.154911 13.5C0.0649674 13.65 0.00500488 13.81 0.00500488 14C0.00500488 14.55 0.454724 15 1.00438 15H14.9956C15.5453 15 15.995 14.55 15.995 14C15.995 13.81 15.935 13.65 15.8451 13.5ZM8.99938 12.99H7.00063V10.99H8.99938V12.99ZM7.00063 9.99H8.99938V4.99H7.00063V9.99Z" fill="#FFB020"/>
                            </svg>
                            {{ customer.translations.representation_of_medal }}
                          </p>
                        </div>
                        <div class="text-left">
                          <div>
                            <div class="d-flex align-items-center gap-1 mb-1">
                              <div class="form-check d-flex flex-column gap-1">
                                <div>
                                  <input class="form-check-input" type="checkbox" value="" id="optionallogo" @click="updateMylarLogo($event)" />
                                  <label class="form-check-label" for="optionallogo"> {{ customer.translations.optional_mylar_logo }} </label>
                                </div>
                                <span class="charge-medal">{{ customer.translations.artwork_charge }}</span>
                              </div>
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 1.6665C5.40835 1.6665 1.66669 5.40817 1.66669 9.99984C1.66669 14.5915 5.40835 18.3332 10 18.3332C14.5917 18.3332 18.3334 14.5915 18.3334 9.99984C18.3334 5.40817 14.5917 1.6665 10 1.6665ZM9.37502 6.6665C9.37502 6.32484 9.65835 6.0415 10 6.0415C10.3417 6.0415 10.625 6.32484 10.625 6.6665V10.8332C10.625 11.1748 10.3417 11.4582 10 11.4582C9.65835 11.4582 9.37502 11.1748 9.37502 10.8332V6.6665ZM10.7667 13.6498C10.725 13.7582 10.6667 13.8415 10.5917 13.9248C10.5084 13.9998 10.4167 14.0582 10.3167 14.0998C10.2167 14.1415 10.1084 14.1665 10 14.1665C9.89169 14.1665 9.78335 14.1415 9.68335 14.0998C9.58335 14.0582 9.49169 13.9998 9.40835 13.9248C9.33335 13.8415 9.27502 13.7582 9.23335 13.6498C9.19169 13.5498 9.16669 13.4415 9.16669 13.3332C9.16669 13.2248 9.19169 13.1165 9.23335 13.0165C9.27502 12.9165 9.33335 12.8248 9.40835 12.7415C9.49169 12.6665 9.58335 12.6082 9.68335 12.5665C9.88335 12.4832 10.1167 12.4832 10.3167 12.5665C10.4167 12.6082 10.5084 12.6665 10.5917 12.7415C10.6667 12.8248 10.725 12.9165 10.7667 13.0165C10.8084 13.1165 10.8334 13.2248 10.8334 13.3332C10.8334 13.4415 10.8084 13.5498 10.7667 13.6498Z" fill="#75767A"/>
                              </svg>
                            </div>
                            <p style="color: #696F8C;">{{ customer.translations.please_note_this }}</p>
                            <p style="color: #696F8C;">{{ customer.translations.mylar_logo_not_funded }}</p>
                            <p style="color: #696F8C;">{{ customer.translations.mylar_desc_1_1 }}</p>
                            <p style="color: #696F8C;">{{ customer.translations.mylar_desc_2 }}</p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="card-content">
              <div class="order-form">
                <!-- <CategoryBullets :customer="customer" /> -->
                <div class="col-12 d-flex justify-content-between align-items-center mb-2 p-0">
                  <div class="d-flex gap-2 align-items-center">
                    <a href="customer_views" class="back-btn">
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.5867 2.66663H10.4134C5.56002 2.66663 2.66669 5.55996 2.66669 10.4133V21.5733C2.66669 26.44 5.56002 29.3333 10.4134 29.3333H21.5734C26.4267 29.3333 29.32 26.44 29.32 21.5866V10.4133C29.3334 5.55996 26.44 2.66663 21.5867 2.66663ZM24 17H10.4134L14.4267 21.0133C14.8134 21.4 14.8134 22.04 14.4267 22.4266C14.2267 22.6266 13.9734 22.72 13.72 22.72C13.4667 22.72 13.2134 22.6266 13.0134 22.4266L7.29335 16.7066C7.10669 16.52 7.00002 16.2666 7.00002 16C7.00002 15.7333 7.10669 15.48 7.29335 15.2933L13.0134 9.57329C13.4 9.18663 14.04 9.18663 14.4267 9.57329C14.8134 9.95996 14.8134 10.6 14.4267 10.9866L10.4134 15H24C24.5467 15 25 15.4533 25 16C25 16.5466 24.5467 17 24 17Z" fill="#3F2021"/>
                      </svg>
                    </a>
                    <h3 class="m-0 order-flow-title fs-26">{{ customer.translations.place_your_order }}</h3>
                  </div>
                  <div class="jersey-options w-100" style="max-width: 217px;">
                    <select v-model="selectedAgeGroup" :class="{'has-value': selectedAgeGroup}" class="styled-dropdown">
                      <option v-for="ageGroup in ageGroups" :key="ageGroup" :value="ageGroup">
                        {{ ageGroup }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <ul class="list-unstyled category-bullet p-0 gap-2 my-32 ff-mont">
                      <li v-for="(ageGroup, index) in visibleAgeGroups" :key="index">
                        <a
                          :class="['bullet-link', { 'active-link': activeIndex >= index }]"
                          @click="updateSelectedAgeGroup(ageGroup)"
                        >
                          <div class="bullet-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M18 11.9999H21C21.2117 11.9999 21.4139 11.9106 21.556 11.7531C21.698 11.5964 21.7676 11.3862 21.7464 11.1752L21.1993 5.70181C21.0455 4.16304 19.985 2.89374 18.4974 2.46894L15.2059 1.52851C15.025 1.47729 14.8316 1.49484 14.6646 1.57906C12.9968 2.41404 11.0043 2.41404 9.33548 1.57906C9.16771 1.49484 8.97473 1.47721 8.79383 1.52851L5.50231 2.46894C4.01513 2.89374 2.95493 4.16304 2.80118 5.70181L2.25368 11.1752C2.23276 11.3861 2.30161 11.5964 2.44373 11.7531C2.58616 11.9106 2.78798 11.9999 3.00001 11.9999H6.00001V21.7499C6.00001 22.1645 6.33586 22.4999 6.75001 22.4999H17.25C17.6645 22.4999 18 22.1645 18 21.7499V11.9999ZM18 10.4999V8.24994C18 7.83541 17.6645 7.49994 17.25 7.49994C16.8355 7.49994 16.5 7.83541 16.5 8.24994V11.2499V20.9999H7.50001V11.2499V8.24994C7.50001 7.83541 7.16416 7.49994 6.75001 7.49994C6.33586 7.49994 6.00001 7.83541 6.00001 8.24994V10.4999H3.82876L4.29383 5.85129C4.38608 4.92766 5.02223 4.16596 5.91428 3.91179L8.93408 3.04906C10.8754 3.92214 13.1258 3.92214 15.0659 3.04906L18.0857 3.91186C18.9778 4.16671 19.6143 4.92774 19.7066 5.85129L20.1716 10.4999H18ZM14.25 7.49994H9.75001C9.33586 7.49994 9.00001 7.16446 9.00001 6.74994C9.00001 6.33541 9.33586 5.99994 9.75001 5.99994H14.25C14.6645 5.99994 15 6.33541 15 6.74994C15 7.16446 14.6645 7.49994 14.25 7.49994ZM10.9145 16.4208C11.0222 16.4743 11.1368 16.4999 11.2493 16.4999C11.5247 16.4999 11.7894 16.3484 11.9209 16.0854L14.9209 10.0854C15.0374 9.85321 15.0249 9.57706 14.888 9.35589C14.751 9.13471 14.51 8.99994 14.25 8.99994H9.75001C9.33586 8.99994 9.00001 9.33541 9.00001 9.74994C9.00001 10.1645 9.33586 10.4999 9.75001 10.4999H13.0364L10.5791 15.4145C10.3938 15.785 10.544 16.2355 10.9145 16.4208Z" fill="white"/>
                            </svg>
                          </div>
                          <span>{{ ageGroup }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="jersey-selection d-flex justify-content-between mb-1 align-items-end">
                  <h4 class="order-subtitle mb-0">Jerseys</h4>
                  <button class="guide-btn no-btn m-0" @click="openModal">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <g clip-path="url(#clip0_2931_3764)">
                        <path d="M10.7477 20.5106L20.4568 10.4243C21.8438 8.98339 21.8169 7.56944 20.376 6.18242L17.4942 3.40838C16.0533 2.02136 14.6393 2.0483 13.2523 3.4892L3.5432 13.5755C2.15619 15.0164 2.18313 16.4303 3.62402 17.8173L6.50581 20.5914C7.94671 21.9784 9.36067 21.9515 10.7477 20.5106Z" stroke="#C8102F" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M12.5588 4.20972L16.161 7.67726" stroke="#C8102F" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M4.23672 12.855L7.11852 15.629" stroke="#C8102F" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M7.04542 9.93712L10.613 13.4407" stroke="#C8102F" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M9.78479 7.09155L11.9461 9.17208" stroke="#C8102F" stroke-width="1.5" stroke-linecap="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_2931_3764">
                          <rect width="24" height="24" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    {{ customer.translations.show_size_guide }}
                  </button>
                  <SizeGuideModal
                    :visible="isModalVisible"
                    :currentUser="currentUser"
                    :customer="customer"
                    :vendor="vendor"
                    :type="'jerseys'"
                    @close="isModalVisible = false"
                  />
                </div>
                
                <table class="table-striped table light-bg m-0 order-table order-table-edit radius-20 colored-th-table colored-table-odd">
                  <thead>
                    <tr>
                      <th>{{ customer.translations.colour }}</th>
                      <th v-for="variant in variants">{{ variant.size }}</th>
                      <th>{{ customer.translations.total }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="jersey in jerseys" :key="jersey.id" :id="`jersey_${jersey.id}`">
                      <td>
                        <div class="d-flex flex-column gap-1 align-items-center">
                          <img :src="jersey.image_url" class="product-img" width="74" alt="Jersey Image" />
                          <p style="color: #4F2D2CE5; font-size: 11px;" class="mb-0">
                            {{ customer.soccer_sports ? jersey.color : jersey.title }}
                          </p>
                        </div>
                      </td>
                      
                      <td v-for="variant in jersey.variants" :key="variant.id">
                        <div v-if="matchingJerseyVariant(jersey, variant.size)">
                          <div class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1" v-if="hasInventory(variant)">
                            <button v-if="hasInventory(variant)" type="button" class="no-btn" @click="decrementJerseyQuantity(jersey.id, variant.id)">-</button>
                            <input
                              type="number"
                              class="w-100 text-center"
                              @input="validateInput('jerseys',jersey.id, variant.id, variant.quantity)"
                              :id="variantInputId(jersey, variant)"
                              v-model="jerseyVariantQuantities[selectedAgeGroup][jersey.id].variants[variant.id]"
                              @blur="checkValidations(jersey.id, variant.id, 'Jerseys')"
                              min="0"
                              :max="variant.quantity"
                            />
                            <button v-if="hasInventory(variant)" type="button" class="no-btn" @click="incrementJerseyQuantity(jersey.id, variant.id)">+</button>
                          </div>
                          <div v-else class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1">
                            <input
                              type="text"
                              class="w-100 text-center"
                              :id="variantInputId(jersey, variant)"
                              value="-"
                              disabled
                            />
                          </div>
                          <p v-if="hasInventory(variant)" class="inventory-count m-0">{{ variant.quantity }} available</p>
                          <p v-else class="inventory-count m-0">{{ customer.translations.out_of_stock }}</p>
                        </div>
                        <div v-else>
                          <div class="d-flex align-items-center justify-content-center light-bg jersey-counter mx-auto mb-1 opacity-8">
                            -
                          </div>
                          <p class="inventory-count m-0">{{ customer.translations.sold_out }}</p>
                        </div>
                      </td>

                      <td>
                        <p id="`${product.id}_${variant.id}_total_jersey`">{{ getJerseyTotalQuantity(jersey.id) }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 pl-0">
          <div v-if="isOrderCompleted" class="card radius-20 summary-order">
            <h3 class="order-title m-0">{{ customer.translations.order_summary }}</h3>
            <ul class="list-unstyled d-flex justify-content-between flex-wrap row-gap-3 p-0 my-32">
              <li class="w-50">
                <div class="d-flex align-items-start basic-group gap-1">
                  <div class="summary-icon">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3070_8243)">
                      <path d="M19.375 1.25H0.62501C0.279854 1.25 1.08085e-05 1.52984 1.08085e-05 1.875V16.875C-0.000965752 17.0411 0.0642685 17.2008 0.18126 17.3187L1.43126 18.5687C1.54919 18.6857 1.70887 18.7509 1.87501 18.75H8.125C8.38656 18.7508 8.62097 18.5887 8.71249 18.3437L9.99999 14.9062L11.2875 18.3437C11.379 18.5887 11.6134 18.7508 11.875 18.75H18.125C18.2911 18.7509 18.4508 18.6857 18.5687 18.5687L19.8187 17.3187C19.9357 17.2008 20.001 17.0411 20 16.875V1.875C20 1.52984 19.7202 1.25 19.375 1.25ZM1.25001 6.24999H2.50001V6.87499C2.53215 7.68682 2.02196 8.42155 1.25001 8.67499V6.24999ZM18.75 16.6187L17.8687 17.5H12.3062L10.625 13.0125V9.37499H9.37499V13.0125L7.69375 17.5H2.13126L1.25001 16.6187V9.94373C2.72981 9.68362 3.79442 8.37682 3.75 6.87499V4.99999H16.25V6.87499C16.2056 8.37682 17.2702 9.68362 18.75 9.94373V16.6187ZM18.75 8.67499C17.978 8.42155 17.4678 7.68682 17.5 6.87499V6.24999H18.75V8.67499ZM18.75 3.75H1.25001V2.5H18.75V3.75Z" fill="#C8102F"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_3070_8243">
                      <rect width="20" height="20" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div>
                    <h6 class="title">{{ customer.translations.shorts }}</h6>
                    <h3 class="value">{{ getAllShortsTotalQuantity() }}</h3>
                    <p class="capacity">{{ customer.translations.maximum_quantity }} (<span>{{ customer.soccer_shorts_quota }}</span>) </p>
                  </div>
                </div>
              </li>
              <li class="w-50">
                <div class="d-flex align-items-start basic-group gap-1">
                  <div class="summary-icon">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.7916 4.43335H2.83329C2.39607 4.43335 2.04163 4.78779 2.04163 5.22502V6.82502C2.04163 7.26224 2.39607 7.61668 2.83329 7.61668H10.7916C11.2289 7.61668 11.5833 7.26224 11.5833 6.82502V5.22502C11.5833 4.78779 11.2289 4.43335 10.7916 4.43335Z" stroke="#C8102F" stroke-miterlimit="10"/>
                      <path d="M12.3833 15.375C12.3758 16.0339 12.1755 16.6761 11.8073 17.2225C11.4391 17.769 10.919 18.1956 10.3111 18.4499C9.70326 18.7042 9.03426 18.7751 8.38664 18.6536C7.73902 18.5322 7.1411 18.2239 6.66664 17.7667L4.56664 15.7C4.27024 15.4052 4.03516 15.0545 3.87497 14.6684C3.71478 14.2822 3.63264 13.8681 3.6333 13.45V7.6167H9.99997V11.5917L11.4 12.9917C11.8609 13.4464 12.1794 14.0256 12.3166 14.6584C12.3615 14.8946 12.3838 15.1346 12.3833 15.375Z" stroke="#C8102F" stroke-miterlimit="10"/>
                      <path d="M17.9583 12.1917C17.9775 12.8533 17.7993 13.5056 17.4464 14.0655C17.0936 14.6255 16.582 15.0677 15.9769 15.3358C15.3717 15.604 14.7005 15.6859 14.0487 15.5712C13.3968 15.4565 12.7939 15.1503 12.3167 14.6917C12.1795 14.0589 11.8609 13.4798 11.4 13.025L10 11.5917V7.61668H10.8333C11.0543 7.61668 11.2663 7.52889 11.4226 7.37261C11.5789 7.21633 11.6667 7.00436 11.6667 6.78335V5.22502C11.656 5.0113 11.5635 4.8099 11.4083 4.66252C11.2532 4.51514 11.0473 4.43308 10.8333 4.43335H15.6083V8.40835L17.0083 9.80835C17.3163 10.1232 17.5588 10.4959 17.7218 10.905C17.8849 11.314 17.9653 11.7514 17.9583 12.1917Z" stroke="#C8102F" stroke-miterlimit="10"/>
                      <path d="M16.3666 1.25H8.40824C7.97102 1.25 7.61658 1.60444 7.61658 2.04167V3.64167C7.61658 4.07889 7.97102 4.43333 8.40824 4.43333H16.3666C16.8038 4.43333 17.1582 4.07889 17.1582 3.64167V2.04167C17.1582 1.60444 16.8038 1.25 16.3666 1.25Z" stroke="#C8102F" stroke-miterlimit="10"/>
                    </svg>
                  </div>
                  <div>
                    <h6 class="title">{{ customer.translations.socks }}</h6>
                    <h3 class="value">{{ getAllSocksTotalQuantity() }}</h3>
                    <p class="capacity">{{ customer.translations.maximum_quantity }} (<span>{{ customer.soccer_socks_quota }}</span>) </p>
                  </div>
                </div>
              </li>
              <li class="w-50">
                <div class="d-flex align-items-start basic-group gap-1">
                  <div class="summary-icon">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 1C5.47721 1 1 5.47721 1 11C1 16.5228 5.47721 21 11 21C16.5228 21 21 16.5228 21 11C20.9938 5.47982 16.5202 1.00618 11 1ZM18.7443 5.79671L18.0513 8.74268L14.4001 9.95964L11.3333 7.50667V3.89225L14.8333 2.49235C16.418 3.21029 17.7718 4.35417 18.7443 5.79671ZM18.8 16.1151L15.4943 16.637L13.6943 14.2593L14.6113 10.5926L18.222 9.38932L20.2904 11.8713C20.1494 13.3867 19.6375 14.8442 18.8 16.1151ZM6.52035 16.6393L3.20003 16.1151C2.36247 14.8439 1.85075 13.3861 1.70996 11.8703L3.77669 9.38835L7.38737 10.5916L8.30436 14.2603L6.52035 16.6393ZM1.6696 10.8757C1.68734 9.38867 2.0625 7.92757 2.76335 6.61605L3.30404 8.91602L1.6696 10.8757ZM8.92692 14L8.04264 10.4593L11 8.09359L13.958 10.4603L13.0731 14H8.92692ZM18.696 8.91471L19.2367 6.61475C19.9375 7.92627 20.3127 9.38721 20.3304 10.8743L18.696 8.91471ZM13.9243 2.13835L11 3.30794L8.06006 2.14372C9.96305 1.50944 12.0202 1.50765 13.9243 2.13835ZM7.15072 2.5L10.6667 3.89339V7.50667L7.59994 9.95964L3.94873 8.74268L3.2557 5.79671C4.22494 4.35938 5.57292 3.21842 7.15072 2.5ZM3.757 16.8779L6.46663 17.306L8.10531 19.8727C6.3986 19.3122 4.88916 18.2726 3.757 16.8779ZM9.06104 20.1294L7.07194 17.0153L8.83333 14.6667H13.1667L14.9533 17.0267L13.2201 20.0643C11.8566 20.4001 10.4349 20.4229 9.06136 20.131L9.06104 20.1294ZM14.146 19.7863L15.5667 17.3L18.2443 16.8776C17.1686 18.2023 15.7515 19.208 14.146 19.7863Z" fill="#C8102F" stroke="#C8102F" stroke-width="0.3"/>
                    </svg>
                  </div>
                  <div>
                    <h6 class="title">{{ customer.translations.balls }}</h6>
                    <h3 class="value">{{ getAllBallsTotalQuantity() }}</h3>
                    <p class="capacity">{{ customer.translations.maximum_quantity }} (<span>{{ customer.soccer_balls_quota }}</span>) </p>
                  </div>
                </div>
              </li>
              <li class="w-50">
                <div class="d-flex align-items-start basic-group gap-1">
                  <div class="summary-icon">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_3070_8474" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                      <path d="M0 1.90735e-06H20V20H0V1.90735e-06Z" fill="white"/>
                      </mask>
                      <g mask="url(#mask0_3070_8474)">
                      <path d="M9.33213 7.15151C8.36064 7.25269 7.45635 7.58042 6.67314 8.08081L2.71533 0.585889H7.32002L10.0466 5.74956" stroke="#C8102F" stroke-width="0.9375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M17.2837 0.58584L13.4173 8.16943C12.6497 7.65029 11.7591 7.30029 10.7982 7.17217C10.5298 7.13623 10.256 7.11787 9.97786 7.11787C9.75989 7.11787 9.54426 7.1292 9.33215 7.15146L10.0466 5.74951L12.6794 0.58584H17.2837Z" stroke="#C8102F" stroke-width="0.9375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.4173 8.16953C12.6498 7.65039 11.7591 7.30039 10.7982 7.17227C10.5298 7.13633 10.256 7.11797 9.97788 7.11797C9.75991 7.11797 9.54429 7.1293 9.33218 7.15156C8.36069 7.25274 7.4564 7.58047 6.67319 8.08086C4.96343 9.17266 3.82983 11.0871 3.82983 13.266C3.82983 16.6617 6.58257 19.4141 9.97788 19.4141C13.3732 19.4141 16.1259 16.6617 16.1259 13.266C16.1259 11.1449 15.0513 9.27422 13.4173 8.16953Z" stroke="#C8102F" stroke-width="0.9375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.7723 13.2661C13.7723 15.3617 12.0734 17.0605 9.9778 17.0605C7.88222 17.0605 6.18335 15.3617 6.18335 13.2661C6.18335 11.1705 7.88222 9.47164 9.9778 9.47164C12.0734 9.47164 13.7723 11.1705 13.7723 13.2661Z" stroke="#C8102F" stroke-width="0.9375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                    </svg>
                  </div>
                  <div>
                    <h6 class="title">{{ customer.translations.medals }}</h6>
                    <h3 class="value">{{ getAllMedalsTotalQuantity() }}</h3>
                    <p class="capacity">{{ customer.translations.maximum_quantity }} (<span>{{ customer.soccer_medals_quota }}</span>) </p>
                  </div>
                </div>
              </li>
            </ul>
            <!-- jersey items table -->
            <template v-for="ageGroup in customer.age_groups.flat()">
              <div v-if="isTotalAgeGroupQuantityPresent(ageGroup)" class="table-responsive mb-3">
                Jerseys: {{ ageGroup }}
                <table class="table light-bg m-0 order-table radius-8 summary-table">
                  <thead>
                    <tr>
                      <th>Color</th>
                      <th v-for="variant in variants" :key="variant.id">{{ variant.size }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="jersey in jerseys">
                      <tr v-if="hasJerseyQuantityInAgeGroup(ageGroup, jersey.id)">
                        <td>
                          <div class="d-flex flex-column gap-1 align-items-center">
                            <img :src="jersey.image_url" class="product-img" width="74" alt="Jersey Image" />
                            <p style="color: #4F2D2CE5; font-size: 11px;">
                              {{ customer.soccer_sports ? jersey.color : jersey.title }}
                            </p>
                          </div>
                        </td>
                        <td v-for="variant in jersey.variants">
                          {{getVariantQuantitySummary(jersey, variant, ageGroup)}}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </template>
            <div class="d-flex mb-1 gap-2">
              <button v-show="!isMonetaryProgram" href="javascript:void(0)" class="btn outlined-primary-btn px-1 w-50" @click="backToJerseysScreen">{{ customer.translations.back }}</button>
              <button class="btn primary-btn px-1 w-50" @click="openConfirmOrderModal">
                {{ customer.translations.checkout }}
              </button>
            </div>
            <button class="text-center btn modal-secondary-btn w-100" @click="openCancelOrderModal">
              {{ customer.translations.cancel_order }}
            </button>
          </div>
          <div v-else class="card radius-20 summary-order">
            <div class="card-content">
              <h3 class="order-title">{{ customer.translations.order_summary }}</h3>
              <div class="d-flex flex-wrap row-gap-3 summary">
                <div class="d-flex w-50 align-items-center gap-1">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.10646 7.74658C8.08646 7.74658 8.07313 7.74658 8.05313 7.74658C8.01979 7.73992 7.97313 7.73992 7.93313 7.74658C5.99979 7.68658 4.53979 6.16658 4.53979 4.29325C4.53979 2.38659 6.09313 0.833252 7.99979 0.833252C9.90646 0.833252 11.4598 2.38659 11.4598 4.29325C11.4531 6.16658 9.98646 7.68658 8.12646 7.74658C8.11979 7.74658 8.11313 7.74658 8.10646 7.74658ZM7.99979 1.83325C6.64646 1.83325 5.5398 2.93992 5.5398 4.29325C5.5398 5.62659 6.57979 6.69992 7.90646 6.74658C7.93979 6.73992 8.03313 6.73992 8.1198 6.74658C9.42646 6.68658 10.4531 5.61325 10.4598 4.29325C10.4598 2.93992 9.35313 1.83325 7.99979 1.83325Z" fill="#C8102F"/>
                    <path d="M8.11307 15.0334C6.80641 15.0334 5.49307 14.7001 4.49974 14.0334C3.57307 13.4201 3.06641 12.5801 3.06641 11.6667C3.06641 10.7534 3.57307 9.90675 4.49974 9.28675C6.49974 7.96008 9.73974 7.96008 11.7264 9.28675C12.6464 9.90008 13.1597 10.7401 13.1597 11.6534C13.1597 12.5667 12.6531 13.4134 11.7264 14.0334C10.7264 14.7001 9.41974 15.0334 8.11307 15.0334ZM5.05307 10.1267C4.41307 10.5534 4.06641 11.1001 4.06641 11.6734C4.06641 12.2401 4.41974 12.7867 5.05307 13.2067C6.71307 14.3201 9.51307 14.3201 11.1731 13.2067C11.8131 12.7801 12.1597 12.2334 12.1597 11.6601C12.1597 11.0934 11.8064 10.5467 11.1731 10.1267C9.51307 9.02008 6.71307 9.02008 5.05307 10.1267Z" fill="#C8102F"/>
                  </svg>
                  <div>
                    <h5>Associate Name</h5>
                    <p class="details">{{ customer.club_name }}</p>
                  </div>
                </div>
                <div class="d-flex w-50 align-items-center gap-1" v-if="customer.mfjd_age_group">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.10671 7.24658C6.04004 7.23992 5.96004 7.23992 5.88671 7.24658C4.30004 7.19325 3.04004 5.89325 3.04004 4.29325C3.04004 2.65992 4.36004 1.33325 6.00004 1.33325C7.63337 1.33325 8.96004 2.65992 8.96004 4.29325C8.95337 5.89325 7.69337 7.19325 6.10671 7.24658Z" stroke="#C8102F" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.9401 2.66675C12.2334 2.66675 13.2734 3.71341 13.2734 5.00008C13.2734 6.26008 12.2734 7.28675 11.0267 7.33341C10.9734 7.32675 10.9134 7.32675 10.8534 7.33341" stroke="#C8102F" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.77335 9.70675C1.16002 10.7867 1.16002 12.5467 2.77335 13.6201C4.60669 14.8467 7.61335 14.8467 9.44669 13.6201C11.06 12.5401 11.06 10.7801 9.44669 9.70675C7.62002 8.48675 4.61335 8.48675 2.77335 9.70675Z" stroke="#C8102F" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.2267 13.3333C12.7067 13.2333 13.16 13.0399 13.5334 12.7533C14.5734 11.9733 14.5734 10.6866 13.5334 9.90659C13.1667 9.62659 12.72 9.43992 12.2467 9.33325" stroke="#C8102F" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <div>
                    <h5>{{ customer.translations.group_name }}: </h5>
                    <p class="details">My First Jersey ({{ customer.mfjd_age_group }})</p>
                  </div>
                </div>
                <div class="d-flex w-50 align-items-center gap-1">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.80005 15.1666H6.20005C2.90671 15.1666 1.50671 13.76 1.50671 10.4733V7.47998C1.50671 7.20665 1.73338 6.97998 2.00671 6.97998C2.28005 6.97998 2.50671 7.20665 2.50671 7.47998V10.4733C2.50671 13.2 3.47338 14.1666 6.20005 14.1666H9.79338C12.52 14.1666 13.4867 13.2 13.4867 10.4733V7.47998C13.4867 7.20665 13.7134 6.97998 13.9867 6.97998C14.26 6.97998 14.4867 7.20665 14.4867 7.47998V10.4733C14.4934 13.76 13.0867 15.1666 9.80005 15.1666Z" fill="#C8102F"/>
                    <path d="M7.99996 8.49992C7.26663 8.49992 6.59996 8.21325 6.12663 7.68658C5.65329 7.15992 5.43329 6.47325 5.50663 5.73992L5.95329 1.28659C5.97996 1.03325 6.19329 0.833252 6.45329 0.833252H9.56663C9.82663 0.833252 10.04 1.02659 10.0666 1.28659L10.5133 5.73992C10.5866 6.47325 10.3666 7.15992 9.89329 7.68658C9.39996 8.21325 8.73329 8.49992 7.99996 8.49992ZM6.89996 1.83325L6.49996 5.83992C6.45329 6.28659 6.58663 6.70659 6.86663 7.01325C7.43329 7.63992 8.56663 7.63992 9.13329 7.01325C9.41329 6.69992 9.54663 6.27992 9.49996 5.83992L9.09996 1.83325H6.89996Z" fill="#C8102F"/>
                    <path d="M12.2066 8.49992C10.8533 8.49992 9.64663 7.40659 9.50663 6.05992L9.03996 1.38659C9.02663 1.24659 9.07329 1.10659 9.16663 0.999919C9.25996 0.893252 9.39329 0.833252 9.53996 0.833252H11.5733C13.5333 0.833252 14.4466 1.65325 14.72 3.66659L14.9066 5.51992C14.9866 6.30658 14.7466 7.05325 14.2333 7.61992C13.72 8.18658 13 8.49992 12.2066 8.49992ZM10.0933 1.83325L10.5066 5.95992C10.5933 6.79325 11.3666 7.49992 12.2066 7.49992C12.7133 7.49992 13.1666 7.30659 13.4933 6.95325C13.8133 6.59992 13.96 6.12659 13.9133 5.61992L13.7266 3.78659C13.52 2.27992 13.0333 1.83325 11.5733 1.83325H10.0933Z" fill="#C8102F"/>
                    <path d="M3.76001 8.49992C2.96668 8.49992 2.24668 8.18658 1.73335 7.61992C1.22001 7.05325 0.980014 6.30658 1.06001 5.51992L1.24001 3.68659C1.52001 1.65325 2.43335 0.833252 4.39335 0.833252H6.42668C6.56668 0.833252 6.70001 0.893252 6.80001 0.999919C6.90001 1.10659 6.94001 1.24659 6.92668 1.38659L6.46001 6.05992C6.32001 7.40659 5.11335 8.49992 3.76001 8.49992ZM4.39335 1.83325C2.93335 1.83325 2.44668 2.27325 2.23335 3.79992L2.05335 5.61992C2.00001 6.12659 2.15335 6.59992 2.47335 6.95325C2.79335 7.30659 3.24668 7.49992 3.76001 7.49992C4.60001 7.49992 5.38001 6.79325 5.46001 5.95992L5.87335 1.83325H4.39335Z" fill="#C8102F"/>
                    <path d="M9.66671 15.1666H6.33337C6.06004 15.1666 5.83337 14.9399 5.83337 14.6666V12.9999C5.83337 11.5999 6.60004 10.8333 8.00004 10.8333C9.40004 10.8333 10.1667 11.5999 10.1667 12.9999V14.6666C10.1667 14.9399 9.94004 15.1666 9.66671 15.1666ZM6.83337 14.1666H9.16671V12.9999C9.16671 12.1599 8.84004 11.8333 8.00004 11.8333C7.16004 11.8333 6.83337 12.1599 6.83337 12.9999V14.1666Z" fill="#C8102F"/>
                  </svg>
                  <div>
                    <h5>{{ customer.translations.vendor }}: </h5>
                    <p class="details" v-if="vendor && vendor.name">{{ vendor.name }}</p>
                  </div>
                </div>
              </div>
              <!-- quantity -->
              <div class="d-flex radius-8 light-bg my-3 w-100 text-center">
                <div class="p-1 w-50" style="border-right: 1px solid #DDC8C433;">
                  <h4 class="sum-value">{{ customer.soccer_jerseys_quota }}</h4>
                  <h6 class="sum-title">{{ customer.translations.maximum_quantity }}</h6>
                </div>
                <div class="x-seprator"></div>
                <div class="p-1 w-50">
                  <h4 id="jersey-total-quantity" class="sum-value">{{ getAllJerseysTotalQuantity() }}</h4>
                  <h6 class="sum-title">{{ customer.translations.ordered }}</h6>
                </div>
              </div>
<!--               jersey items table-->
              <template v-for="ageGroup in customer.age_groups.flat()">
                <div v-if="isTotalAgeGroupQuantityPresent(ageGroup)" class="table-responsive mb-3">
                  Jerseys: {{ ageGroup }}
                  <table class="table light-bg m-0 order-table radius-8 summary-table">
                    <thead>
                      <tr>
                        <th>Color</th>
                        <th v-for="variant in variants" :key="variant.id">{{ variant.size }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="jersey in jerseys">
                        <tr v-if="hasJerseyQuantityInAgeGroup(ageGroup, jersey.id)">
                          <td>
                            <div class="d-flex flex-column gap-1 align-items-center">
                              <img :src="jersey.image_url" class="product-img" width="74" alt="Jersey Image" />
                              <p style="color: #4F2D2CE5; font-size: 11px;">
                                {{ customer.soccer_sports ? jersey.color : jersey.title }}
                              </p>
                            </div>
                          </td>
                          <td v-for="variant in jersey.variants">
                            {{getVariantQuantitySummary(jersey, variant, ageGroup)}}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </template>

              <div class="d-flex mb-1 gap-2">
                <button href="javascript:void(0)" class="btn outlined-primary-btn px-1 w-50" @click="nextAgeGroup()">{{ customer.translations.next_age_group }}</button>
                <button class="btn primary-btn px-1 w-50" @click="openNextProductsScreen">
                  Complete
                </button>
              </div>
              <button class="text-center btn modal-secondary-btn w-100" @click="openCancelOrderModal">
                Cancel Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <CheckoutPage
    v-if="showCheckoutPage"
    :currentUser="currentUser"
    :customer="customer"
    :vendor="vendor"
    :jerseys="jerseys"
    :variants="variants"
    :orderImage="orderImage"
    :jerseyVariantQuantities="jerseyVariantQuantities"
    :shortsVariantQuantities="shortsVariantQuantities"
    :socksVariantQuantities="socksVariantQuantities"
    :ballsVariantQuantities="ballsVariantQuantities"
    :hasJerseyQuantityInAgeGroup="hasJerseyQuantityInAgeGroup"
    :isTotalAgeGroupQuantityPresent="isTotalAgeGroupQuantityPresent"
  />

  <CancelOrderModal
    :visible="showCancelOrderModal"
    :currentUser="currentUser"
    :customer="customer"
    :vendor="vendor"
    @close="showCancelOrderModal = false"
  />

  <ConfirmOrderModal
    :jerseys="jerseys"
    :variants="variants"
    :shortsVariants="shortsVariants"
    :socksVariants="socksVariants"
    :ballsVariants="ballsVariants"
    :medalsVariants="medalsVariants"
    :visible="showConfirmOrderModal"
    :currentUser="currentUser"
    :customer="customer"
    :vendor="vendor"
    :orderImage="orderImage"
    :jerseyVariantQuantities="jerseyVariantQuantities"
    :shortsVariantQuantities="shortsVariantQuantities"
    :socksVariantQuantities="socksVariantQuantities"
    :ballsVariantQuantities="ballsVariantQuantities"
    :medalsVariantQuantities="medalsVariantQuantities"
    @openConfirmAddressModal="showConfirmAddressModal = true"
    @close="showConfirmOrderModal = false"
  />

  <ConfirmAddressModal
    :visible="showConfirmAddressModal"
    @close="showConfirmAddressModal = false"
    @openTheCheckoutPage="showCheckoutPage = true"
  />
</template>

<script>
import SizeGuideModal from './SizeGuideModal.vue';
import OrderSummary from './OrderSummary.vue';
import CategoryBullets from './CategoryBullets.vue';
import CancelOrderModal from './CancelOrderModal.vue';
import ConfirmOrderModal from './ConfirmOrderModal.vue';
import ConfirmAddressModal from './ConfirmAddressModal.vue';
import InformRmm from './InformRmm.vue';
import InformRmmModal from './InformRmmModal.vue';
import LimitExceeded from './LimitExceeded.vue';
import LimitExceededModal from './LimitExceededModal.vue';
import CheckoutPage from './CheckoutPage.vue';

export default {
  components: {
    SizeGuideModal,
    OrderSummary,
    CategoryBullets,
    CancelOrderModal,
    ConfirmOrderModal,
    ConfirmAddressModal,
    InformRmm,
    InformRmmModal,
    LimitExceeded,
    LimitExceededModal,
    CheckoutPage,
  },
  data() {
    return {
      isModalVisible: false,
      showCancelOrderModal: false,
      showConfirmOrderModal: false,
      showConfirmAddressModal: false,
      showInformRmmAlert: false,
      showInformRmmModal: false,
      showLimitExceededAlert: false,
      showLimitExceededModal: false,
      showCheckoutPage: false,
      selectedAgeGroup: '',
      currentAgeIndex: 0,
      ageGroups: [],
      activeIndex: 0,
      jerseyVariantQuantities: this.initializeJerseyVariantQuantities(this.jerseys, this.variants),
      shortsVariantQuantities: this.initializeVariantQuantities(this.shorts, this.shortsVariants),
      socksVariantQuantities: this.initializeVariantQuantities(this.socks, this.socksVariants),
      ballsVariantQuantities: this.initializeVariantQuantities(this.balls, this.ballsVariants),
      medalsVariantQuantities: this.initializeMedalsVariantQuantities(this.medals, this.medalsVariants),
      isOrderCompleted: this.customer.program_type === 'Monetary' ?  true : false,
      isMonetaryProgram: this.customer.program_type === 'Monetary' ? true : false,
      maxOrderableJerseys: this.customer.soccer_jerseys_quota,
      maxOrderableShorts: this.customer.soccer_shorts_quota,
      maxOrderableSocks: this.customer.soccer_socks_quota,
      maxOrderableBalls: this.customer.soccer_balls_quota,
      maxOrderableMedals: this.customer.soccer_medals_quota,
      totalJerseysOrdered: 0,
      totalShortsOrdered: 0,
      totalSocksOrdered: 0,
      totalBallsOrdered: 0,
    };
  },
  created() {
    if (this.customer && this.customer.age_groups && this.customer.age_groups.length > 0) {
      this.selectedAgeGroup = this.customer.age_groups[0];
    }
  },
  props: {
    currentUser: Object,
    customer: Object,
    variants: Array,
    jerseys: Array,
    vendor: Object,
    rmm: Object,
    orderImage: File,
    shorts: Object,
    shortsVariants: Array,
    socks: Array,
    socksVariants: Array,
    balls: Array,
    ballsVariants: Array,
    medals: Array,
    medalsVariants: Array,
  },
  mounted() {
    this.ageGroups = this.customer.age_groups.flat();
    this.selectedAgeGroup = this.ageGroups[0];
  },
  computed: {
    visibleAgeGroups() {
      return this.ageGroups;
    },
  },
  watch: {
    activeIndex(newIndex) {
      this.selectedAgeGroup = this.ageGroups[newIndex];
    },
    selectedAgeGroup(newAgeGroup) {
      let newIndex = this.ageGroups.indexOf(newAgeGroup);
      if (newIndex >= 0) {
        this.activeIndex = Math.min(newIndex, this.ageGroups.length);
      }
    },
  },
  methods: {
    initializeJerseyVariantQuantities(products, variants) {
      let quantities = {};

      if (!products || !this.customer || !this.customer.age_groups) return quantities;

      this.customer.age_groups.flat().forEach((ageGroup) => {
        quantities[ageGroup] = {};

        products.forEach((product) => {
          quantities[ageGroup][product.id] = {
            variants: {}
          };

          product.variants.forEach((variant) => {
            quantities[ageGroup][product.id].variants[variant.id] = null;
          });
        });
      });

      return quantities;
    },
    initializeVariantQuantities(products, variants) {
      let quantities = {};

      if (!products || !this.customer) return quantities;

      products.forEach((product) => {
        quantities[product.id] = {
          variants: {}
        };

        product.variants.forEach((variant) => {
          quantities[product.id].variants[variant.id] = null;
        });
      });

      return quantities;
    },
    initializeMedalsVariantQuantities(products, variants) {
      let quantities = {};

      if (!products || !this.customer) return quantities;

      products.forEach((product) => {
        quantities[product.id] = {
          variants: {}
        };

        variants.forEach((variant) => {
          quantities[product.id].variants[variant.id] = null;
          quantities[product.id].variants['addMylarLogo'] = false;
        });
      });

      return quantities;
    },
    matchingJerseyVariant(jersey, size) {
      return jersey.variants.find(jv => jv.size === size) || null;
    },
    matchingShortVariant(short, size) {
      return short.variants.find(jv => jv.size === size) || null;
    },
    matchingSocksVariant(sock, size) {
      return sock.variants.find(jv => jv.size === size) || null;
    },
    matchingBallsVariant(ball, size) {
      return ball.variants.find(jv => jv.size === size) || null;
    },
    matchingMedalsVariant(medal, size) {
      return medal.variants.find(jv => jv.size === size) || null;
    },
    variantInputId(product, variant) {
      return `${product.id}_${variant.id}`;
    },
    hasInventory(variant) {
      return variant.quantity > 0;
    },
    getInventory(variantId, jerseyId) {
      let jersey = this.jerseys.find(j => j.id === jerseyId);
      if (jersey) {
        let variant = jersey.variants.find(v => v.id === variantId);
        return variant ? variant.quantity : 0;
      }
      return 0; // Return 0 if no matching jersey or variant is found
    },
    getShortsInventory(variantId) {
      let variant = this.shortsVariants.find(v => v.id == variantId);
      return variant ? variant.quantity : 0;
    },
    getSocksInventory(variantId) {
      let variant = this.socksVariants.find(v => v.id == variantId);
      return variant ? variant.quantity : 0;
    },
    getBallsInventory(variantId) {
      let variant = this.ballsVariants.find(v => v.id == variantId);
      return variant ? variant.quantity : 0;
    },
    getMedalsInventory(variantId) {
      let variant = this.medalsVariants.find(v => v.id == variantId);
      return variant ? variant.quantity : 0;
    },
    updateSelectedAgeGroup(ageGroup) {
      this.selectedAgeGroup = ageGroup;
    },
    updateMylarLogo(event) {
      const isChecked = event.target.checked;

      Object.keys(this.medalsVariantQuantities).forEach((medalId) => {
        let variants = this.medalsVariantQuantities[medalId].variants;
        variants.addMylarLogo = isChecked;
      });

      console.log('Checkbox checked:', isChecked);
    },
    isTotalAgeGroupQuantityPresent(ageGroup) {
      const ageGroupData = this.jerseyVariantQuantities[ageGroup];
      if (!ageGroupData) return false;

      let total = 0;

      Object.keys(ageGroupData).forEach((jerseyId) => {
        const variants = ageGroupData[jerseyId]?.variants || {};
        total += Object.values(variants).reduce((sum, qty) => sum + (parseInt(qty, 10) || 0), 0);
      });

      return total > 0;
    },
    getJerseyTotalQuantity(jerseyId) {
      let total = 0;

      Object.keys(this.jerseyVariantQuantities).forEach((ageGroup) => {
        let productQuantities = this.jerseyVariantQuantities[ageGroup][jerseyId];

        if (productQuantities) {
          total += Object.values(productQuantities.variants).reduce((sum, qty) => {
            return sum + (isNaN(parseInt(qty)) ? 0 : parseInt(qty));
          }, 0);
        }
      });

      return total;
    },
    getJerseyVariantTotalQuantity(jerseyId, variantId) {
      let total = 0;

      Object.keys(this.jerseyVariantQuantities).forEach((ageGroup) => {
        let productQuantities = this.jerseyVariantQuantities[ageGroup][jerseyId].variants[variantId];

        if (productQuantities) {
          total += productQuantities
        }
      });

      return total;
    },

    getExcludedJerseyVariantTotalQuantity(excludeVariantId) {
      let total = 0;
      let selectedAgeGroup = this.selectedAgeGroup;

      // Loop through all age groups
      Object.keys(this.jerseyVariantQuantities).forEach((ageGroup) => {
        Object.keys(this.jerseyVariantQuantities[ageGroup]).forEach((jerseyId) => {
          // Loop through all variants of each jersey
          Object.keys(this.jerseyVariantQuantities[ageGroup][jerseyId].variants).forEach((variantId) => {
            let productQuantities = this.jerseyVariantQuantities[ageGroup][jerseyId].variants[variantId];

            // If it's the selected age group, exclude the variant
            if (ageGroup == selectedAgeGroup) {
              if (variantId != excludeVariantId) {
                if (productQuantities) {
                  total += productQuantities;
                }
              }
            } else {
              // For all other age groups, include all variants
              if (productQuantities) {
                total += productQuantities;
              }
            }
          });
        });
      });

      return total;
    },

    getExcludedShortVariantTotalQuantity(excludeVariantId) {
      let total = 0;

      // Loop through all products in shortsVariantQuantities
      Object.keys(this.shortsVariantQuantities).forEach((productId) => {
        let productVariants = this.shortsVariantQuantities[productId].variants;

        // Loop through each variant in the product
        Object.keys(productVariants).forEach((variantId) => {
          let variantQuantity = productVariants[variantId];

          // Add to total if it's not the excluded variant ID
          if (variantId != excludeVariantId) {
            total += variantQuantity || 0; // Treat null/undefined as 0
          }
        });
      });

      return total;
    },

    getExcludedSocksVariantTotalQuantity(excludeVariantId) {
      let total = 0;

      // Loop through all products in shortsVariantQuantities
      Object.keys(this.socksVariantQuantities).forEach((productId) => {
        let productVariants = this.socksVariantQuantities[productId].variants;

        // Loop through each variant in the product
        Object.keys(productVariants).forEach((variantId) => {
          let variantQuantity = productVariants[variantId];

          // Add to total if it's not the excluded variant ID
          if (variantId != excludeVariantId) {
            total += variantQuantity || 0; // Treat null/undefined as 0
          }
        });
      });

      return total;
    },



    getAllJerseysTotalQuantity() {
      let total = 0;

      Object.keys(this.jerseyVariantQuantities).forEach((ageGroup) => {
        Object.keys(this.jerseyVariantQuantities[ageGroup]).forEach((jerseyId) => {
          let jerseyQuantities = this.jerseyVariantQuantities[ageGroup][jerseyId];

          if (jerseyQuantities && jerseyQuantities.variants) {
            total += Object.values(jerseyQuantities.variants).reduce((sum, qty) => {
              return sum + (Number(qty) || 0);
            }, 0);
          }
        });
      });

      return total;
    },
    getShortTotalQuantity(shortId) {
      let total = 0;

      if (this.shortsVariantQuantities[shortId]) {
        total += Object.values(this.shortsVariantQuantities[shortId].variants).reduce((sum, qty) => {
          return sum + (parseInt(qty, 10) || 0);
        }, 0);
      }

      return total;
    },
    getAllShortsTotalQuantity() {
      let total = 0;
      let shortIds = new Set();

      let shorts = Object.keys(this.shortsVariantQuantities);

      shorts.forEach((shortId) => {
        if (!shortIds.has(shortId)) {
          shortIds.add(shortId);

          total += Object.values(this.shortsVariantQuantities[shortId].variants).reduce((sum, qty) => {
            return sum + (parseInt(qty, 10) || 0);
          }, 0);
        }
      });

      return total;
    },
    getSockTotalQuantity(sockId) {
      let total = 0;

      if (this.socksVariantQuantities[sockId]) {
        total += Object.values(this.socksVariantQuantities[sockId].variants).reduce((sum, qty) => {
          return sum + (parseInt(qty, 10) || 0);
        }, 0);
      }

      return total;
    },
    getAllSocksTotalQuantity() {
      let total = 0;
      let sockIds = new Set();

      let socks = Object.keys(this.socksVariantQuantities);

      socks.forEach((sockId) => {
        if (!sockIds.has(sockId)) {
          sockIds.add(sockId);

          total += Object.values(this.socksVariantQuantities[sockId].variants).reduce((sum, qty) => {
            return sum + (parseInt(qty, 10) || 0);
          }, 0);
        }
      });

      return total;
    },
    getBallTotalQuantity(ballId) {
      let total = 0;

      if (this.ballsVariantQuantities[ballId]) {
        total += Object.values(this.ballsVariantQuantities[ballId].variants).reduce((sum, qty) => {
          return sum + (parseInt(qty, 10) || 0);
        }, 0);
      }

      return total;
    },
    getAllBallsTotalQuantity() {
      let total = 0;
      let ballIds = new Set();

      let balls = Object.keys(this.ballsVariantQuantities);

      balls.forEach((ballId) => {
        if (!ballIds.has(ballId)) {
          ballIds.add(ballId);

          total += Object.values(this.ballsVariantQuantities[ballId].variants).reduce((sum, qty) => {
            return sum + (parseInt(qty, 10) || 0);
          }, 0);
        }
      });

      return total;
    },
    getMedalTotalQuantity(medalId, medalVariantId = null) {
      let total = 0;

      if (medalVariantId != null) {
        return this.medalsVariantQuantities[medalId].variants[medalVariantId];
      }

      if (this.medalsVariantQuantities[medalId]) {
        total += Object.values(this.medalsVariantQuantities[medalId].variants).reduce((sum, qty) => {
          return sum + (parseInt(qty, 10) || 0);
        }, 0);
      }

      return total;
    },
    getAllMedalsTotalQuantity() {
      let total = 0;
      let medalIds = new Set();

      let medals = Object.keys(this.medalsVariantQuantities);

      medals.forEach((medalId) => {
        if (!medalIds.has(medalId)) {
          medalIds.add(medalId);

          total += Object.values(this.medalsVariantQuantities[medalId].variants).reduce((sum, qty) => {
            return sum + (parseInt(qty, 10) || 0);
          }, 0);
        }
      });

      return total;
    },

    checkValidations(product_id, variant_id, product_type = null) {
      if (product_type === 'Jerseys') {
        let totalOrderedAcrossGroups = this.getAllJerseysTotalQuantity();
        // Get total ordered for the current jersey in the current group
        let totalOrderedForJersey = this.getJerseyVariantTotalQuantity(product_id, variant_id);
        let totalOrderedForJerseyOtherThenCurrentVariant = this.getExcludedJerseyVariantTotalQuantity(variant_id);
        let inventory = this.getInventory(variant_id, product_id);
        let remainingOrderable = this.maxOrderableJerseys - totalOrderedForJerseyOtherThenCurrentVariant;

        var allowedJerseys = totalOrderedAcrossGroups;

        // Validate against inventory and remaining orderable quantity
        if (remainingOrderable == 0) {
          this.showInformRmmModal = true;
          $("#" + product_id + "_" + variant_id).val(0);
          this.jerseyVariantQuantities[this.selectedAgeGroup][product_id].variants[variant_id] = 0;
          allowedJerseys = 0;
          return;
        }
        
        if (totalOrderedAcrossGroups > this.maxOrderableJerseys) {
          this.showInformRmmModal = true;

          if (remainingOrderable <= inventory) {

            let setInventory = this.maxOrderableJerseys - totalOrderedForJerseyOtherThenCurrentVariant
            $("#" + product_id + "_" + variant_id).val(setInventory);
            this.jerseyVariantQuantities[this.selectedAgeGroup][product_id].variants[variant_id] = setInventory;
            allowedJerseys = setInventory;
            this.totalJerseysOrdered = totalOrderedForJerseyOtherThenCurrentVariant  + setInventory;
          }
        }
          // else if(remainingOrderable > inventory) {
          //   allowedJerseys = inventory;
          //   $("#" + product_id + "_" + variant_id).val(inventory);
          //   this.jerseyVariantQuantities[this.selectedAgeGroup][product_id].variants[variant_id] = inventory;
          // }
        // } else if (totalOrderedForJersey > inventory) {
        //   this.showLimitExceededModal = true;
        //   $("#" + product_id + "_" + variant_id).val(inventory);
        //   this.jerseyVariantQuantities[this.selectedAgeGroup][product_id].variants[variant_id] = inventory;
        //   allowedJerseys = inventory;
        // }
      }

      else if (product_type == 'Shorts') {
        const totalOrdered = this.getAllShortsTotalQuantity();
        const remainingOrderable = this.maxOrderableShorts - this.getExcludedShortVariantTotalQuantity(variant_id);
        if (remainingOrderable == 0) {
          $("#" + product_id + "_" + variant_id).val(0);
          this.shortsVariantQuantities[product_id].variants[variant_id] = 0;
          return;
        }

        if (totalOrdered > this.maxOrderableShorts) {
          this.showInformRmmModal = true;

          if (remainingOrderable <= this.getShortsInventory(variant_id)) {

            let setInventory = this.maxOrderableJerseys - this.getExcludedShortVariantTotalQuantity(variant_id);
            $("#" + product_id + "_" + variant_id).val(setInventory);
            this.shortsVariantQuantities[product_id].variants[variant_id] = setInventory;
          }
        }
      }
      else if (product_type == 'Socks') {

        const totalOrdered = this.getAllSocksTotalQuantity();
        const remainingOrderable = this.maxOrderableSocks - this.getExcludedSocksVariantTotalQuantity(variant_id);
        if (remainingOrderable == 0) {
          $("#" + product_id + "_" + variant_id).val(0);
          this.socksVariantQuantities[product_id].variants[variant_id] = 0;
          return;
        }

        if (totalOrdered > this.maxOrderableSocks) {
          this.showInformRmmModal = true;

          if (remainingOrderable <= this.getSocksInventory(variant_id)) {

            let setInventory = this.maxOrderableSocks - this.getExcludedSocksVariantTotalQuantity(variant_id);
            $("#" + product_id + "_" + variant_id).val(setInventory);
            this.socksVariantQuantities[product_id].variants[variant_id] = setInventory;
          }
        }

      }

      else if (product_type == 'Balls') {
        let totalOrdered = this.getBallTotalQuantity(product_id);
        let inventory = this.getBallsInventory(variant_id);
        
        if (this.getAllBallsTotalQuantity() > this.maxOrderableBalls) {
          this.showInformRmmAlert = true;
          $("#" + product_id + "_" + variant_id).val(this.maxOrderableBalls);
          this.ballsVariantQuantities[product_id].variants[variant_id] = this.maxOrderableBalls;
        }
      }

      else if (product_type == 'Medals') {
        let totalOrdered = this.getMedalTotalQuantity(product_id);
        let inventory = this.getMedalsInventory(variant_id);
        
        if (totalOrdered > inventory) {
          // this.showLimitExceededAlert = true;
          this.showLimitExceededModal = true;
          $("#" + product_id + "_" + variant_id).val(inventory);
          this.medalsVariantQuantities[this.selectedAgeGroup][product_id][variant_id] = inventory;
        }
        else if (this.getAllMedalsTotalQuantity() > this.maxOrderableMedals) {
          // this.showInformRmmAlert = true;
          this.showInformRmmModal = true;
          $("#" + product_id + "_" + variant_id).val(this.maxOrderableMedals);
          this.medalsVariantQuantities[product_id].variants[variant_id] = this.maxOrderableMedals;
        }
      }
    },
    // validateInput(jerseyId, variantId, maxQuantity, type) {
    //   let currentValue = this.jerseyVariantQuantities[this.selectedAgeGroup][jerseyId].variants[variantId];
    //   if (currentValue > maxQuantity) {
    //     this.jerseyVariantQuantities[this.selectedAgeGroup][jerseyId].variants[variantId] = maxQuantity;
    //   } else if (currentValue < 0) {
    //     this.jerseyVariantQuantities[this.selectedAgeGroup][jerseyId].variants[variantId] = 0;
    //   }
    // },
    validateInput(itemType, itemId, variantId, maxQuantity) {
      let currentValue;

      switch (itemType) {
        case 'jerseys':
          currentValue = this.jerseyVariantQuantities[this.selectedAgeGroup][itemId].variants[variantId];
          if (currentValue > maxQuantity) {
            this.jerseyVariantQuantities[this.selectedAgeGroup][itemId].variants[variantId] = maxQuantity;
          } else if (currentValue < 0) {
            this.jerseyVariantQuantities[this.selectedAgeGroup][itemId].variants[variantId] = 0;
          }
          break;

        case 'shorts':
          currentValue = this.shortsVariantQuantities[itemId].variants[variantId];
          if (currentValue > maxQuantity) {
            this.shortsVariantQuantities[itemId].variants[variantId] = maxQuantity;
          } else if (currentValue < 0) {
            this.shortsVariantQuantities[itemId].variants[variantId] = 0;
          }
          break;
        case 'socks': // New case for socks
          currentValue = this.socksVariantQuantities[itemId].variants[variantId];
          if (currentValue > maxQuantity) {
            this.socksVariantQuantities[itemId].variants[variantId] = maxQuantity;
          } else if (currentValue < 0) {
            this.socksVariantQuantities[itemId].variants[variantId] = 0;
          }
          break;
        case 'balls': // New case for socks
          currentValue = this.ballsVariantQuantities[itemId].variants[variantId];
          if (currentValue > maxQuantity) {
            this.ballsVariantQuantities[itemId].variants[variantId] = maxQuantity;
          } else if (currentValue < 0) {
            this.ballsVariantQuantities[itemId].variants[variantId] = 0;
          }
          break;
        case 'medals': // New case for socks
          currentValue = this.medalsVariantQuantities[itemId].variants[variantId];
          if (currentValue > maxQuantity) {
            this.medalsVariantQuantities[itemId].variants[variantId] = maxQuantity;
          } else if (currentValue < 0) {
            this.medalsVariantQuantities[itemId].variants[variantId] = 0;
          }
          break;
        default:
          console.error(`Unknown item type: ${itemType}`);
      }
    },
    nextAgeGroup() {
      // Check if there is a next age group
      if (this.currentAgeIndex < this.customer.age_groups.length - 1) {
        this.currentAgeIndex++;
      } else {
        this.currentAgeIndex = 0;
      }

      this.selectedAgeGroup = this.customer.age_groups[this.currentAgeIndex][0];

      // this.updateSelectedAgeGroup(this.selectedAgeGroup);
    },
    hasJerseyQuantityInAgeGroup(ageGroup, jerseyId) {
      const jerseyData = this.jerseyVariantQuantities[ageGroup]?.[jerseyId];
      if (!jerseyData) return false; // If the jersey doesn't exist, return false

      return Object.values(jerseyData.variants || {}).some((quantity) => quantity > 0);
    },
    decrementJerseyQuantity(jerseyId, variantId) {
      let currentQuantity = this.jerseyVariantQuantities[this.selectedAgeGroup][jerseyId].variants[variantId];

      if (currentQuantity > 0) {
        this.jerseyVariantQuantities[this.selectedAgeGroup][jerseyId].variants[variantId] = currentQuantity - 1;
      }
    },
    incrementJerseyQuantity(jerseyId, variantId) {
      let currentQuantity = this.jerseyVariantQuantities[this.selectedAgeGroup][jerseyId].variants[variantId];

      if (currentQuantity < this.getInventory(variantId)) {
        this.jerseyVariantQuantities[this.selectedAgeGroup][jerseyId].variants[variantId] = currentQuantity + 1;

        this.checkValidations(jerseyId, variantId, 'Jerseys');
      }
    },
    decrementShortQuantity(shortId, variantId) {
      let currentQuantity = this.shortsVariantQuantities[shortId].variants[variantId];

      if (currentQuantity > 0) {
        this.shortsVariantQuantities[shortId].variants[variantId] = currentQuantity - 1;
      }
    },
    incrementShortQuantity(shortId, variantId) {
      let currentQuantity = this.shortsVariantQuantities[shortId].variants[variantId];

      if (currentQuantity < this.getShortsInventory(variantId)) {
        this.shortsVariantQuantities[shortId].variants[variantId] = currentQuantity + 1;

        this.checkValidations(shortId, variantId, 'Shorts');
      }
    },
    decrementSockQuantity(sockId, variantId) {
      let currentQuantity = this.socksVariantQuantities[sockId].variants[variantId];

      if (currentQuantity > 0) {
        this.socksVariantQuantities[sockId].variants[variantId] = currentQuantity - 1;
      }
    },
    incrementSockQuantity(sockId, variantId) {
      let currentQuantity = this.socksVariantQuantities[sockId].variants[variantId];

      if (currentQuantity < this.getSocksInventory(variantId)) {
        this.socksVariantQuantities[sockId].variants[variantId] = currentQuantity + 1;

        this.checkValidations(sockId, variantId, 'Socks');
      }
    },
    decrementBallQuantity(ballId, variantId) {
      let currentQuantity = this.ballsVariantQuantities[ballId].variants[variantId];

      if (currentQuantity > 0) {
        this.ballsVariantQuantities[ballId].variants[variantId] = currentQuantity - 1;
      }
    },
    incrementBallQuantity(ballId, variantId) {
      let currentQuantity = this.ballsVariantQuantities[ballId].variants[variantId];

      if (currentQuantity < this.getBallsInventory(variantId)) {
        this.ballsVariantQuantities[ballId].variants[variantId] = currentQuantity + 1;

        this.checkValidations(ballId, variantId, 'Balls');
      }
    },
    decrementMedalQuantity(medalId, variantId) {
      let currentQuantity = this.medalsVariantQuantities[medalId].variants[variantId];

      if (currentQuantity > 0) {
        this.medalsVariantQuantities[medalId].variants[variantId] = currentQuantity - 1;
      }
    },
    incrementMedalQuantity(medalId, variantId) {
      let currentQuantity = this.medalsVariantQuantities[medalId].variants[variantId];

      if (currentQuantity < this.getMedalsInventory(variantId)) {
        this.medalsVariantQuantities[medalId].variants[variantId] = currentQuantity + 1;

        this.checkValidations(medalId, variantId, 'Medals');
      }
    },
    openModal() {
      this.isModalVisible = true;
    },
    openCancelOrderModal() {
      this.showCancelOrderModal = true
    },
    openConfirmOrderModal() {
      this.showConfirmOrderModal = true
    },
    openConfirmAddressModal() {
      this.showConfirmAddressModal = true;
    },
    openCheckoutPage() {
      this.showCheckoutPage = true;
    },
    openNextProductsScreen() {
      this.isOrderCompleted = true
    },
    backToJerseysScreen() {
      this.isOrderCompleted = false
    },
    getVariantQuantitySummary(jersey, variant, ageGroup) {
      const quantity = this.jerseyVariantQuantities[ageGroup]?.[jersey.id]?.variants?.[variant.id];
      return quantity ? quantity : '-'; // Return 'N/A' if quantity is undefined
    }
  }
};
</script>

<style scoped>
.bg-blurred {
  background: #00000067;
}
.guide-btn {
  display: flex;
  color: #3F2021;
  gap: 8px;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  font-family: Inter;
}
.x-seprator {
  width: 1px;
  height: 100%;
  background: #DDC8C433;
}
.sum-title {
  font-size: 11px;
  font-weight: 400;
  color: #7B4C4699;
  margin: 0;
}
.sum-value {
  font-size: 28px;
  font-weight: 600;
  color: #3F2021;
}
.charge-medal {
  color: #75767A;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
}

</style>
