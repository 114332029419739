<template>
  <PlaceYourOrder
    v-if="showPlaceYourOrder"
    :currentUser="currentUser"
    :customer="customer"
    :jerseys="jerseys"
    :variants="variants"
    :vendor="vendor"
    :rmm="rmm"
    :orderImage="image"
    :shorts="shorts"
    :shortsVariants="shortsVariants"
    :socks="socks"
    :socksVariants="socksVariants"
    :balls="balls"
    :ballsVariants="ballsVariants"
    :medals="medals"
    :medalsVariants="medalsVariants"
  />

  <div v-else class="card active-users radius-20 full-h-card" style="padding-top: 110px;">
    <div class="card-header border-0 bg-transparent">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 text-center">
          <div v-if="existingCustomerLogoUrl">
            <div class="row">
              <div class="col-12 d-flex gap-2 align-items-center mb-32">
                <a href="customer_views" class="back-btn">
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.5867 2.66663H10.4134C5.56002 2.66663 2.66669 5.55996 2.66669 10.4133V21.5733C2.66669 26.44 5.56002 29.3333 10.4134 29.3333H21.5734C26.4267 29.3333 29.32 26.44 29.32 21.5866V10.4133C29.3334 5.55996 26.44 2.66663 21.5867 2.66663ZM24 17H10.4134L14.4267 21.0133C14.8134 21.4 14.8134 22.04 14.4267 22.4266C14.2267 22.6266 13.9734 22.72 13.72 22.72C13.4667 22.72 13.2134 22.6266 13.0134 22.4266L7.29335 16.7066C7.10669 16.52 7.00002 16.2666 7.00002 16C7.00002 15.7333 7.10669 15.48 7.29335 15.2933L13.0134 9.57329C13.4 9.18663 14.04 9.18663 14.4267 9.57329C14.8134 9.95996 14.8134 10.6 14.4267 10.9866L10.4134 15H24C24.5467 15 25 15.4533 25 16C25 16.5466 24.5467 17 24 17Z" fill="#3F2021"/>
                  </svg>
                </a>
                <h3 class="m-0 order-flow-title">Club Logo</h3>
              </div>
            </div>

            <div id="uploaded_url">
              <div v-if="!logoUploaded">
                <div class="row">
                  <div class="col-md-12 pt-2 pb-2">
                    <img :src="existingCustomerLogoUrl" class="responsive-image" style="max-width: 500px;" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Show "Logo Verification" only if existingCustomerLogoUrl is false -->
          <div v-if="!existingCustomerLogoUrl">
            <div class="row">
              <div class="col-12 d-flex gap-2 align-items-center mb-2">
                <a href="customer_views" class="back-btn">
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.5867 2.66663H10.4134C5.56002 2.66663 2.66669 5.55996 2.66669 10.4133V21.5733C2.66669 26.44 5.56002 29.3333 10.4134 29.3333H21.5734C26.4267 29.3333 29.32 26.44 29.32 21.5866V10.4133C29.3334 5.55996 26.44 2.66663 21.5867 2.66663ZM24 17H10.4134L14.4267 21.0133C14.8134 21.4 14.8134 22.04 14.4267 22.4266C14.2267 22.6266 13.9734 22.72 13.72 22.72C13.4667 22.72 13.2134 22.6266 13.0134 22.4266L7.29335 16.7066C7.10669 16.52 7.00002 16.2666 7.00002 16C7.00002 15.7333 7.10669 15.48 7.29335 15.2933L13.0134 9.57329C13.4 9.18663 14.04 9.18663 14.4267 9.57329C14.8134 9.95996 14.8134 10.6 14.4267 10.9866L10.4134 15H24C24.5467 15 25 15.4533 25 16C25 16.5466 24.5467 17 24 17Z" fill="#3F2021"/>
                  </svg>
                </a>
                <h3 class="m-0 order-flow-title">Logo Verification</h3>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="dont-forgot d-flex gap-1">
                <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0_3222_238" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1" y="3" width="14" height="14">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99998 3.33337C4.31998 3.33337 1.33331 6.32004 1.33331 10C1.33331 13.68 4.31998 16.6667 7.99998 16.6667C11.68 16.6667 14.6666 13.68 14.6666 10C14.6666 6.32004 11.68 3.33337 7.99998 3.33337ZM7.99998 10.6667C7.63331 10.6667 7.33331 10.3667 7.33331 10V7.33337C7.33331 6.96671 7.63331 6.66671 7.99998 6.66671C8.36665 6.66671 8.66665 6.96671 8.66665 7.33337V10C8.66665 10.3667 8.36665 10.6667 7.99998 10.6667ZM7.33331 12V13.3334H8.66665V12H7.33331Z" fill="black"/>
                  </mask>
                  <g mask="url(#mask0_3222_238)">
                  <rect y="2" width="16" height="16" fill="#2E5AAC"/>
                  </g>
                </svg>
                <p class="m-0">{{ customer.translations.jerseys_with_club_logo }} {{ customer.translations.allow_2_to_3_weeks_for_delivery_2 }}.</p>
              </div>
            </div>
          </div>

          <div class="row" v-show="previewImage">
            <div class="col-md-12 pt-2 pb-2">
              <div class="card-light">
                <img :src="previewImage" class="responsive-image w-100" id="img_prev" />
              </div>
            </div>
          </div>

          <button v-if="showDeleteButton" type="button"
            class="mini_button btn outlined-primary-btn cursor-pointer d-flex gap-1 justify-content-center"
            @click="reloadPage">
            Upload Different Logo
          </button>

          <div class="row mt-2">
            <div class="col-md-6">
              <label class="btn outlined-primary-btn btn-block cursor-pointer px-2 d-flex gap-1 justify-content-center py-14" for="account-upload">
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.875 7.6425H13.7075C11.93 7.6425 10.4825 6.195 10.4825 4.4175V2.25C10.4825 1.8375 10.145 1.5 9.7325 1.5H6.5525C4.2425 1.5 2.375 3 2.375 5.6775V12.3225C2.375 15 4.2425 16.5 6.5525 16.5H12.4475C14.7575 16.5 16.625 15 16.625 12.3225V8.3925C16.625 7.98 16.2875 7.6425 15.875 7.6425ZM9.1475 10.1475C9.035 10.26 8.8925 10.3125 8.75 10.3125C8.6075 10.3125 8.465 10.26 8.3525 10.1475L7.8125 9.6075V12.75C7.8125 13.0575 7.5575 13.3125 7.25 13.3125C6.9425 13.3125 6.6875 13.0575 6.6875 12.75V9.6075L6.1475 10.1475C5.93 10.365 5.57 10.365 5.3525 10.1475C5.135 9.93 5.135 9.57 5.3525 9.3525L6.8525 7.8525C6.905 7.8075 6.9575 7.77 7.0175 7.74C7.0325 7.7325 7.055 7.725 7.07 7.7175C7.115 7.7025 7.16 7.695 7.2125 7.6875C7.235 7.6875 7.25 7.6875 7.2725 7.6875C7.3325 7.6875 7.3925 7.7025 7.4525 7.725C7.46 7.725 7.46 7.725 7.4675 7.725C7.5275 7.7475 7.5875 7.7925 7.6325 7.8375C7.64 7.845 7.6475 7.845 7.6475 7.8525L9.1475 9.3525C9.365 9.57 9.365 9.93 9.1475 10.1475Z" fill="#C8102F"/>
                  <path d="M13.5725 6.60762C14.285 6.61512 15.275 6.61512 16.1225 6.61512C16.55 6.61512 16.775 6.11262 16.475 5.81262C15.395 4.72512 13.46 2.76762 12.35 1.65762C12.0425 1.35012 11.51 1.56012 11.51 1.98762V4.60512C11.51 5.70012 12.44 6.60762 13.5725 6.60762Z" fill="#C8102F"/>
                </svg>
                {{ customer.translations.upload }}
              </label>
            </div>
            <div class="col-md-6">
              <button
                v-if="customer.program_type === 'Monetary'"
                class="btn primary-btn btn-block px-2 d-flex gap-1 justify-content-center py-14"
                @click="monetaryCustomersAfterUpload"
                :disabled="!this.customer.logo && !this.image"
              >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 1.5C4.8675 1.5 1.5 4.8675 1.5 9C1.5 13.1325 4.8675 16.5 9 16.5C13.1325 16.5 16.5 13.1325 16.5 9C16.5 4.8675 13.1325 1.5 9 1.5ZM12.585 7.275L8.3325 11.5275C8.2275 11.6325 8.085 11.6925 7.935 11.6925C7.785 11.6925 7.6425 11.6325 7.5375 11.5275L5.415 9.405C5.1975 9.1875 5.1975 8.8275 5.415 8.61C5.6325 8.3925 5.9925 8.3925 6.21 8.61L7.935 10.335L11.79 6.48C12.0075 6.2625 12.3675 6.2625 12.585 6.48C12.8025 6.6975 12.8025 7.05 12.585 7.275Z" fill="white"/>
                </svg>
                {{ customer.translations.accept }}
              </button>

              <button
                v-else
                class="btn primary-btn btn-block px-2 d-flex gap-1 justify-content-center py-14"
                @click="moveToJerseyView"
                :disabled="!this.customer.logo && !this.image"
              >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 1.5C4.8675 1.5 1.5 4.8675 1.5 9C1.5 13.1325 4.8675 16.5 9 16.5C13.1325 16.5 16.5 13.1325 16.5 9C16.5 4.8675 13.1325 1.5 9 1.5ZM12.585 7.275L8.3325 11.5275C8.2275 11.6325 8.085 11.6925 7.935 11.6925C7.785 11.6925 7.6425 11.6325 7.5375 11.5275L5.415 9.405C5.1975 9.1875 5.1975 8.8275 5.415 8.61C5.6325 8.3925 5.9925 8.3925 6.21 8.61L7.935 10.335L11.79 6.48C12.0075 6.2625 12.3675 6.2625 12.585 6.48C12.8025 6.6975 12.8025 7.05 12.585 7.275Z" fill="white"/>
                </svg>
                Accept
              </button>
            </div>
            <!-- <div class="col-md-4">
              <router-link :to="{ path: '/customer_views' }" class="btn outlined-primary-btn btn-block">
                Back
              </router-link>
            </div> -->
          </div>

          <div class="card mt-2">
            <div class="form-group text-center">
              <input type="file" ref="fileInput" id="account-upload" @change="onFileChange" accept="image/*" hidden />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import { customRef } from 'vue';
  import PlaceYourOrder from './PlaceYourOrder.vue';

  export default {
    components: {
      PlaceYourOrder
    },
    props: {
      currentUser: {
        type: Object,
        required: true
      },
      customer: {
        type: Object,
        default: () => ({})
      },
      variants: {
        type: Object,
        required: true
      },
      jerseys: {
        type: Object,
        required: true
      },
      vendor: {
        type: Object,
        required: true
      },
      rmm: {
        type: Object,
        required: true
      },
      shorts: {
        type: Object,
        required: true
      },
      shortsVariants: {
        type: Object,
        required: true
      },
      socks: {
        type: Object,
        required: true
      },
      socksVariants: {
        type: Object,
        required: true
      },
      balls: {
        type: Object,
        required: true
      },
      ballsVariants: {
        type: Object,
        required: true
      },
      medals: {
        type: Object,
        required: true
      },
      medalsVariants: {
        type: Object,
        required: true
      },
    },
    computed: {
      existingCustomerLogoUrl() {
        return this.customer && this.customer.logo ? this.customer.logo : null;
      }
    },
    data() {
      return {
        image: null,
        previewImage: null,
        showDeleteButton: false,
        showPlaceYourOrder: false,
        logoUploaded: false // Add this flag to track logo upload
      };
    },
    methods: {
      onFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          this.image = file;
          const reader = new FileReader();
          reader.onload = (e) => {
            this.previewImage = e.target.result;
            this.logoUploaded = true; // Set to true after uploading image
          };
          reader.readAsDataURL(file);
          this.showDeleteButton = true;
        }
      },
      reloadPage() {
        this.previewImage = null;
        this.showDeleteButton = false;
        this.logoUploaded = false;
        this.$emit('reloadLogo');

        // Reset the file input value to allow the same file to be selected again
        const fileInput = this.$refs.fileInput;
        if (fileInput) {
          fileInput.value = '';
        }
      },
      monetaryCustomersAfterUpload() {
        console.log('Monetary customer accepted');
      },
      moveToJerseyView() {
        console.log('Place your order', this.showPlaceYourOrder)
        console.log('Move to jersey view')
        this.showPlaceYourOrder = true
        console.log('Place your order', this.showPlaceYourOrder)
      }
    }
  };
</script>

<style>
  .card-light {
    border-radius: 8px;
    background: #F4F6F9;
    padding: 48px;
  }
  .link-text {
    color: #6D7580;
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    border: none;
    background: transparent;
    padding: 0;
    text-align: left;
  }
  .mini_button {
    width: 20% !important;
    color: #6D7580;
    font-size: 10px;
    font-weight: 500;
    width: 100%;
    border: none;
    background: transparent;
    padding: 5px !important;
    text-align: left;
  }
</style>
