<template>
  <Preloader :currentUser="currentUser" v-if="showPreloader" />

  <LogoSelection
    v-if="showLogoSelection"
    :currentUser="currentUser"
    :customer="customer"
    :jerseys="jerseys"
    :variants="variants"
    :vendor="vendor"
    :rmm="rmm"
    :shorts="shorts"
    :shortsVariants="shortsVariants"
    :socks="socks"
    :socksVariants="socksVariants"
    :balls="balls"
    :ballsVariants="ballsVariants"
    :medals="medals"
    :medalsVariants="medalsVariants"
    @selectYes="showLogoUploadView"
    @selectNo="handleNoSelection"
  />

  <div v-if="showPlaceOrder" class="row">
    <div class="col-12">
      <div class="card radius-20 ff-mont tim-card">
        <div class="card-body p-0">
          <p class="intro-title">
            <span>&#128075</span> Hi, {{ currentUser.name }}
          </p>
          <h5 style="color: #3F2021;" class="fw-300 mb-0">
            {{ customer.translations.welcome }} {{ currentUser.name }} {{ customer.translations.from }} {{ customer.club_name }}!
          </h5>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showPlaceOrder" class="row match-height allBlur justify-content-center">
    <div class="col-xl-12 col-lg-12">
      <div class="card active-users radius-20 tim-card">
        <div class="place-container">
          <div class="card-header border-0 bg-transparent text-center">
            <h3 class="intro-title mb-2">{{ customer.translations.place_your_order }}</h3>
            <h5 style="color: #3F2021;" class="mb-0">
              {{ customer.translations.welcome }} {{ currentUser.name }} {{ customer.translations.from }} {{ customer.club_name }}!
            </h5>
          </div>

          <div class="row">
            <SoccerAndHockeyCards :currentUser="currentUser" />
          </div>

          <div class="card-content">
            <div v-if="currentUser.soccer_sports">
              <button v-if="currentUser.verified === 'Verified'" @click="placeSoccerOrder" class="btn primary-btn w-100 text-center py-14">{{ customer.translations.start_your_order }}</button>
              <p v-else class="text-danger">Account not verified</p>
            </div>
            <div v-if="currentUser.hockey_sports">
              <button v-if="currentUser.verified === 'Verified'" @click="placeHockeyOrder" class="btn primary-btn w-100 text-center">{{ customer.translations.start_your_order }}</button>
              <p v-else class="text-danger">Account not verified</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoccerAndHockeyCards from './SoccerAndHockeyCards.vue';
import Preloader from './Preloader.vue';
import LogoSelection from './LogoSelection.vue';
import SizeGuideModal from './SizeGuideModal.vue';

export default {
  components: {
    SoccerAndHockeyCards,
    Preloader,
    LogoSelection,
    SizeGuideModal,
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    variants: {
      type: Object,
      required: true
    },
    jerseys: {
      type: Object,
      required: true
    },
    vendor: {
      type: Object,
      required: true
    },
    rmm: {
      type: Object,
      required: true
    },
    shorts: {
      type: Object,
      required: true
    },
    shortsVariants: {
      type: Object,
      required: true
    },
    socks: {
      type: Object,
      required: true
    },
    socksVariants: {
      type: Object,
      required: true
    },
    balls: {
      type: Object,
      required: true
    },
    ballsVariants: {
      type: Object,
      required: true
    },
    medals: {
      type: Object,
      required: true
    },
    medalsVariants: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      showPlaceOrder: true,
      showPreloader: false,
      showLogoSelection: false
    };
  },
  emits: ['toggleAppContent'],
  methods: {
    placeSoccerOrder() {
      this.showPlaceOrder = false;
      this.showPreloader = true;
      this.showLogoSelection = false;
      this.$emit('toggleAppContent')
      setTimeout(() => {
          this.showPreloader = false;
          this.showLogoSelection = true;
      }, 2000);
      if (this.customer.program_type === 'Monetary'){
        this.showLogoSelection = false;
      }
    },
    placeHockeyOrder() {
      this.showPlaceOrder = false;
      this.showPreloader = true;
      this.showLogoSelection = false;
      this.$emit('toggleAppContent')
      setTimeout(() => {
        this.showPreloader = false;
        this.showLogoSelection = true;
      }, 2000);
    }
  }
};
</script>
